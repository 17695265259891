import React, { useState, useMemo, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ReactComponent as ChevronRight } from '../../../../../assets/icons/chevron-right.svg';
import { InputSearch } from '../../../../InputSearch';
import { Accordion } from '../../../../Accordion';
import AsyncContent from '../../../../Accordion/components/AsyncContent';
import { AccordionHeader } from '../../../../Accordion/components/Header';
import { useMediaTab } from '../../../../../hooks/useMediaTab';
import AssetsList from '../AssetsList';
import styles from './styles.module.scss';

const AssetsModalContent = ({ filter, onChose }) => {
  const [search, setSearch] = useState('');

  const {
    media,
    categories,
    onOpenAccordion,
  } = useMediaTab();

  const getContent = useCallback((items) => (
    <AssetsList
      items={items}
      onChose={onChose}
    />
  ), [onChose]);

  const getHeader = useCallback(({ name, has_child }) => (
    <AccordionHeader
      hasChildren={has_child}
      title={name}
      leftIcons={<ChevronRight width={24} height={24} style={{ fill: has_child ? undefined : '#5E5E5D' }} />}
    />
  ), []);

  const makeItems = useCallback((array, items, parent = null) => array
    .filter((category) => category.parent === parent)
    .map((category) => {
      let content;

      const childCategories = makeItems(array, items, category.relative_path);

      const currentMedia = items.filter((item) => item.parent === category.relative_path);

      if (currentMedia.length === 0 && childCategories.length === 0 && category.has_child) {
        content = <AsyncContent />;
      } else {
        const contentItems = currentMedia.length > 0
          ? getContent(currentMedia)
          : '';

        content = childCategories.length > 0
          ? [
            { content: contentItems },
            ...childCategories,
          ]
          : contentItems;
      }

      return {
        id: category.relative_path,
        category,
        header: getHeader(category),
        content,
      };
    }), [getContent, getHeader]);

  const filterImageList = ['png', 'jpg', 'jpeg', 'gif', 'ico', 'svg', 'webp'];
  const filterAudioList = ['mp3', 'ogg', 'wav'];
  const filterVideoList = ['mp4', 'ogv', 'webm'];

  const mediaFiltered = useMemo(() => {
    let mediaList = media;
    if (filter) {
      switch (filter) {
        case 'image':
          mediaList = media
            .filter((item) => filterImageList.includes((item.extension || '').toLowerCase()));
          break;

        case 'audio':
          mediaList = media
            .filter((item) => filterAudioList.includes((item.extension || '').toLowerCase()));
          break;

        case 'video':
          mediaList = media
            .filter((item) => filterVideoList.includes((item.extension || '').toLowerCase()));
          break;

        case 'attach':
          mediaList = media
            .filter((item) => !filterImageList.includes((item.extension || '').toLowerCase()));
          break;

        default: mediaList = media;
      }
    }

    if (search) {
      mediaList = mediaList
        .filter((item) => item.name.toLowerCase().indexOf(search) !== -1);
    }
    return mediaList;
  }, [filter, filterAudioList, filterImageList, filterVideoList, media, search]);

  const categoriesList = useMemo(
    () => makeItems(categories, mediaFiltered),
    [categories, makeItems, mediaFiltered],
  );

  const mediaNoCategory = useMemo(
    () => mediaFiltered.filter((item) => !item.parent),
    [mediaFiltered],
  );

  return (
    <>

      <InputSearch
        value={search}
        onChange={setSearch}
        className={styles.search}
      />

      <PerfectScrollbar className={styles.ps}>

        <div className={styles.wrap}>

          {categoriesList.map((item, index) => (
            <Accordion
              withBorder={false}
              item={item}
              key={index}
              onOpen={onOpenAccordion}
            />
          ))}

          <AssetsList
            items={mediaNoCategory}
            onChose={onChose}
          />

        </div>

      </PerfectScrollbar>

    </>
  );
};

export default AssetsModalContent;
